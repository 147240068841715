import React from 'react';
import Loading from './Loading';
import { Link } from 'react-router-dom';
import EventGridItem from './EventGridItem';

class UpcomingForYou extends React.Component {
    
    constructor (props){

        super(props);
        this.state = {
            events: [],
            isLoading: 1
        }
        
    }

    componentDidMount() {

        Promise.all([
            fetch('/wp-json/occasiongenius/v1/featured-events?limit=4' ),
          ])
          .then(([res]) => Promise.all([res.json()]))
          .then(([cat_data]) => this.setState({
            events: cat_data.events,
            isLoading: 0
        }));
  
    }

    render() {

        return (
            <>
            
                <div className="flex bg-slate-300 og-upcoming-for-you pt-4">
                    <div className="col-span-12">
                        <div className="flow-root">
                            <p className="text-gray-800 font-semibold mb-2 text-2xl pl-4 pr-4">
                                {/* <Link to="/all" className="text-inherit"> */}
                                    <span className="inline-block text-inherit">Featured Events</span>
                                    {/* <svg className="w-6 inline-block pl-2 text-inherit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504.3 273.6l-112.1 104c-6.992 6.484-17.18 8.218-25.94 4.406c-8.758-3.812-14.42-12.45-14.42-21.1L351.9 288H32C14.33 288 .0002 273.7 .0002 255.1S14.33 224 32 224h319.9l0-72c0-9.547 5.66-18.19 14.42-22c8.754-3.809 18.95-2.075 25.94 4.41l112.1 104C514.6 247.9 514.6 264.1 504.3 273.6z"/></svg> */}
                                {/* </Link> */}
                            </p>
                        </div>
                    </div>  
                </div>                  
                <div className="flex bg-slate-300 og-upcoming-for-you pt-2 pb-4 pl-4 pr-4">
                    {this.state.isLoading ? (

                        <Loading />

                    ) : (
                        <>
                        {this.state.events ? (
                            <div class="tba-featured-events flex snap-x snap-mandatory w-full overflow-scroll md:grid md:grid-cols-12 md:w-auto gap-5">
                                {/* <div className="grid grid-cols-12 px-18 gap-5"> */}


                                    {this.state.events.map((item, index) => (   
                                        <>
                                            <EventGridItem item={item} key={index} />
                                        </>
                                    ))}


                                {/* </div> */}
                            </div>
                            ) : (
                                <>
                                    <p>No events found.</p>
                                </>
                            )}
                        </>
                    )}  

                </div>
            </>
        );

    }

}

export default UpcomingForYou;